import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import '../css/main.css';
import architectureImage from '../images/Agregactus_architecture_v2.png';
import architectureImageZoom from '../images/Agregactus_architecture_v2_zoom.png';
import gif from '../gif/agregactus-v2.gif';  

const ProjectPage = () => {
  return (
    <div className="project-container">
      <Navbar />
      <div className="project-header">
        <h2><a href="https://github.com/BenjaminDemolin/Agregactus_v2">AgregActus v2.0</a></h2>
        <p>
        AgregActus v2.0 marks a significant advancement in automating the creation and posting of tweets. Leveraging web scraping and natural language processing, this Python project eliminates the need for manual intervention. The process is intelligently divided into key phases to ensure a seamless and efficient execution of automatic tweeting.
        </p>
      </div>
      
      <div className="project-gif-container">
        <a href={gif} target="_blank" rel="noopener noreferrer">
          <img className="project-gif" src={gif} alt="Project GIF" />
        </a>
      </div>
      
      <div className="project-details">
        <div className="row">
          <div className="project-card">
            <h3>Step I - Web Scraping of Articles</h3>
            <p>
              The initial step involves web scraping to extract articles from various news websites. Once new articles are identified, their links are stored in a PostgreSQL database, awaiting further processing. This data serves as the foundation for subsequent stages in the project.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="project-card">
            <h3>Step II - Text Reduction with NLTK</h3>
            <p>
              With the article links secured, the project proceeds to extract the text of each article using web scraping. The extracted text is then processed through a Python script utilizing NLTK (Natural Language Toolkit) to condense the content. This reduction is crucial for minimizing costs associated with using ChatGPT, which becomes more expensive for longer texts.
            </p>
          </div>
          <div className="row">
          <div className="project-card">
            <h3>Step III - Tweet Generation and Posting</h3>
            <p>
              The condensed articles are then sent to ChatGPT via its API to generate tweets. These generated tweets are stored in a database. Subsequently, the script enters a loop, requesting tweets from ChatGPT for articles lacking associated tweets in the database. This iterative process ensures comprehensive coverage of all articles.
            </p>
          </div>
          </div>

          <div className="row"></div>
          <div className="project-card">
            <h3>Step IV - Automated Tweeting with Selenium</h3>
            <p>
              Once the tweets are generated, a list is compiled and sent to ChatGPT to determine the best tweet. The selected tweet is then utilized for automated posting. Selenium, a browser automation tool, comes into play at this stage. The script launches Firefox, logs into Twitter, and automates the tweet posting process. The project also allows for configuration of the minimal time gap between each automated tweet, with a default setting of 30 minutes.
            </p>
          </div>
        </div>
      </div>

      <div className="image-container">
        <a href={architectureImageZoom} target="_blank" rel="noopener noreferrer">
          <img className="architecture-img" src={architectureImage} alt="Project Architecture" />
        </a>
      </div>

      <Footer />
    </div>
  );
}

export default ProjectPage;
