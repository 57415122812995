import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const location = useLocation();

  return (
    <nav className="nav-container">
      <div className="container">
        <Link to="/v1" className={`nav-link ${location.pathname === '/v1' ? 'active' : ''}`}>
          v1.0
        </Link>
        <Link to="/" className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}>
          v2.0
        </Link>
      </div>
    </nav>
  );
}

export default Navbar;
