import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import '../css/main.css';
import architectureImage from '../images/Agregactus_architecture_v1.png';
import architectureImageZoom from '../images/Agregactus_architecture_v1_zoom.png';

const ProjectPage = () => {
  return (
    <div className="project-container">
      <Navbar />
      <div className="project-header">
      <h2><a href="https://github.com/BenjaminDemolin/Agregactus-v1.0">AgregActus v1.0</a></h2>
        <p>
        AgregActus v1.0 is a Python project dedicated to generating tweets and associated questions, which are then dispatched via email. The seamless integration of web scraping and natural language processing ensures a streamlined and efficient process for creating and distributing engaging content.
        </p>
      </div>
      <div className="project-details">
        <div className="row">
          <div className="project-card">
            <h3>Step I - Web Scraping of Articles</h3>
            <p>
              The initial step involves web scraping to extract articles from various news websites. Once new articles are identified, their links are stored in a PostgreSQL database, awaiting further processing. This data serves as the foundation for subsequent stages in the project.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="project-card">
            <h3>Step II - Text Reduction with NLTK</h3>
            <p>
              With the article links secured, the project proceeds to extract the text of each article using web scraping. The extracted text is then processed through a Python script utilizing NLTK (Natural Language Toolkit) to condense the content. This reduction is crucial for minimizing costs associated with using ChatGPT, which becomes more expensive for longer texts.
            </p>
          </div>
          <div className="row">
          <div className="project-card">
            <h3>Step III - Tweet Generation and Posting</h3>
            <p>
              The condensed articles are then sent to ChatGPT via its API to generate tweets and questions. These generated tweets and questions are stored in a database. Subsequently, the script enters a loop, requesting tweets and questions from ChatGPT for articles lacking associated tweets and questions in the database. This iterative process ensures comprehensive coverage of all articles.
            </p>
          </div>
          </div>

          <div className="row"></div>
          <div className="project-card">
            <h3>Step IV - Automated Tweeting and Questioning via Gmail</h3>
            <p>
            After generating a tweet and its associated question, the pair is sent via Gmail to someone for review and approval.
            </p>
          </div>
        </div>
      </div>

      <div className="image-container">
        <a href={architectureImageZoom} target="_blank" rel="noopener noreferrer">
          <img className="architecture-img" src={architectureImage} alt="Project Architecture" />
        </a>
      </div>

      <Footer />
    </div>
  );
}

export default ProjectPage;
